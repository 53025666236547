<template>
  <div class="row main">
    <loading :active="loading" :is-full-page="true"></loading>
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
      rel="stylesheet"
    />
    <div class="col-12 organization">
      <div class="box bg-white">
        <div class="logos">
          <img src="../images/mais-logo.svg" alt="Mapa do Impacto Social" />
        </div>

        <section>
          <div class="form-control">
            <label class="form-label" for="">Filtrar</label>
            <input
              class="form-control mb-3"
              placeholder="Pesquisar"
              v-model="searchField"
              @keyup="pesquisar()"
            />

            <label class="form-label">Data de Criação</label>
            <Datepicker
              v-model="dateFilter"
              :format="'dd/MM/yyyy'"
              @update:modelValue="pesquisar()"
              range
            ></Datepicker>
          </div>

          <div class="d-flex py-3 justify-content-end">
            <button @click="onExport()" type="button" class="btn btn-seall">
              Exportar
            </button>
          </div>

          <EasyDataTable
            class="mt-3"
            :headers="columns"
            :items="rows"
            alternating
            buttons-pagination
            dense
          />
        </section>
      </div>
    </div>
  </div>
  <img class="w-100" src="./../images/barra_ods.svg" alt="Barra" />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AuthService from "../../services/auth/auth";
import PoweredBy from "./PoweredBy.vue";
import * as dayjs from "dayjs";
import * as isBetween from "dayjs/plugin/isBetween";
import { mask } from "maska";
import { CsvDataService } from '../javascript/to-csv';
export default {
  name: "MaisManagement",
  data() {
    return {
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      columns: [
        { text: "Nome", value: "name", sortable: true },
        { text: "CNPJ", value: "cnpj" },
        { text: "E-mail", value: "email", sortable: true },
        { text: "Data de Criação", value: "createdDate", sortable: true },
      ],
      rows: [],
      searchField: "",
      maisOrg: [],
      loading: false,
      dateFilter: null,
    };
  },
  mounted() {
    this.$i18n.locale = "pt";
    this.loadAllMaisOrganizations();
    dayjs.extend(isBetween);
  },
  methods: {
    loadAllMaisOrganizations() {
      this.loading = true;

      AuthService.getMaisOrganizations().then((data) => {
        this.maisOrg = data.data;
        this.rows = this.mapData(this.maisOrg);

        this.loading = false;
      });
    },
    pesquisar() {
      const columns = this.columns.map((c) => c.value);

      this.rows = this.dateFilter
        ? this.mapData(
            this.maisOrg.filter((row) => {
              return dayjs(row.createdDate).isBetween(
                this.dateFilter[0],
                this.dateFilter[1],
                "day",
                "[]"
              );
            })
          )
        : this.mapData(this.maisOrg);

      this.rows = this.rows.filter((item) => {
        const value = columns.find((column) => {
          item[column] += "";
          return (
            item[column]
              .toLowerCase()
              .indexOf(this.searchField.toLowerCase()) != -1
          );
        });

        return !!value;
      });
    },
    mapData(data) {
      return data.map((item) => {
        return {
          name: item.name,
          telephone: item.telephone,
          email: item.email,
          cnpj: mask(item.cnpj, "##.###.###/####-##"),
          createdDate: dayjs(item.createdDate).format("DD/MM/YYYY"),
        };
      });
    },
    onExport() {
      CsvDataService.exportToCsv('OSC MAIS.xlsx',this.rows);
    }
  },
  components: {
    Loading,
    PoweredBy,
  },
};
</script>

<style lang="scss">
@import "../css/bootstrap/bootstrap.min.css";
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px lightgray;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #492e85;
  border-radius: 5px;
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
  background-color: #533a8b;
  overflow: hidden;
}

.organization {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 33%;
  background-image: url("./../images/background-create.png");
  background-repeat: repeat;
  background-size: cover;
}

.main {
  background-color: #533a8b;
  height: 98vh;
  max-height: 98vh;
}

.box {
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  min-height: 500px;
  width: 80%;

  h2 {
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #492e85;
    margin: 0vh 0vw;
    padding: 2vh 0vw;
  }

  .logos {
    margin-top: 2rem;
    text-align: center;
    img {
      width: 90%;
      padding: 1rem;
    }
  }
  section {
    min-height: 50vh;
    max-height: 50vh;
    padding: 2rem 5rem;
    width: 100%;
    overflow-y: auto;
    .form-label {
      font-family: "Roboto", sans-serif;
      &.required:after {
        content: " *";
        color: #00b9bd;
      }
    }
  }
  .btn-seall {
    border-color: #1d14ff;
    border-radius: 4px;
    color: #1d14ff;
    font-family: NunitoSans-Regular;
    padding: 0.7rem;
    text-decoration: none;
  }  
}

@media (max-width: 1163px) {
  .image {
    display: none;
  }
  .box {
    section {
      padding: 1rem 2rem;
    }
  }
}
</style>
